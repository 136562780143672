<template>
    <div class="auth_wrapper flex justify-center items-center relative overflow-y-auto">
      <div class="relative left h-full" :style="{'--bg':backgroundImage}">
        <div class="left-info-box">
            <h1 class="fs-46px fw-800 title text-white mb-4">Imagine if Organizations</h1>
            <p class="fs-32 description fw-300 text-white">
                always had the
                <strong class="fw-600">Right Data</strong> and
                <strong class="fw-600">Synchronized  Tools</strong> available to make
                <strong class="fw-600">Smarter</strong> and more
                <strong class="fw-600">Informed</strong> decisions and
                <strong class="fw-600">Repeatable</strong> plays.
            </p>
          </div>
      </div>

      <div class="right relative justify-center bg-primary-three w-full h-full overflow-y-auto">
        <div class="logo absolute top-12 right-10">
            <svg width="253" height="32" viewBox="0 0 253 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0 31.1097V0.521625H6.15306V31.1097H0Z" fill="#1C1C1D"/>
                <path d="M14.2269 31.1097V0.521625H20.2136L32.686 20.9485V0.521625H38.4026V31.1097H32.2287L19.9434 11.1628V31.1097H14.2269Z" fill="#1C1C1D"/>
                <path d="M45.4994 21.1571L51.4861 20.5729C51.8464 22.5898 52.574 24.0712 53.6688 25.0171C54.7775 25.963 56.2672 26.4359 58.1381 26.4359C60.1198 26.4359 61.6096 26.0186 62.6074 25.184C63.619 24.3355 64.1248 23.3479 64.1248 22.2212C64.1248 21.4979 63.91 20.8859 63.4804 20.3851C63.0647 19.8704 62.3302 19.4253 61.277 19.0497C60.5563 18.7994 58.9141 18.3542 56.3504 17.7144C53.0521 16.8937 50.7378 15.8852 49.4074 14.689C47.5365 13.0058 46.6011 10.9541 46.6011 8.53378C46.6011 6.97586 47.0376 5.52227 47.9107 4.173C48.7976 2.80982 50.0657 1.77352 51.7148 1.06411C53.3778 0.354705 55.3803 0 57.7223 0C61.5472 0 64.4228 0.841555 66.3491 2.52466C68.2892 4.20777 69.3078 6.45424 69.4048 9.26406L63.2518 9.5353C62.9885 7.96347 62.4203 6.83676 61.5472 6.15517C60.688 5.45967 59.3923 5.11192 57.66 5.11192C55.8723 5.11192 54.4726 5.48054 53.4609 6.21777C52.8096 6.69071 52.4839 7.32361 52.4839 8.11648C52.4839 8.8398 52.7888 9.4588 53.3986 9.97347C54.1746 10.6272 56.0593 11.3088 59.0527 12.0182C62.0461 12.7276 64.2565 13.4649 65.6839 14.2299C67.1251 14.9811 68.2477 16.0174 69.0514 17.3388C69.8691 18.6463 70.2779 20.2669 70.2779 22.2004C70.2779 23.953 69.7929 25.5944 68.8228 27.1245C67.8527 28.6546 66.4807 29.7952 64.7069 30.5463C62.933 31.2836 60.7226 31.6522 58.0757 31.6522C54.2231 31.6522 51.2644 30.762 49.1995 28.9815C47.1346 27.1871 45.9013 24.579 45.4994 21.1571Z" fill="#1C1C1D"/>
                <path d="M84.4424 31.1097V5.69614H75.3999V0.521625H99.6172V5.69614H90.5955V31.1097H84.4424Z" fill="#1C1C1D"/>
                <path d="M105.758 31.1097V0.521625H118.708C121.965 0.521625 124.328 0.799825 125.797 1.35622C127.28 1.89871 128.464 2.87241 129.351 4.27732C130.238 5.68223 130.682 7.28884 130.682 9.09714C130.682 11.3923 130.01 13.291 128.665 14.7933C127.321 16.2816 125.312 17.2206 122.637 17.6101C123.967 18.389 125.062 19.2445 125.921 20.1764C126.795 21.1084 127.966 22.7637 129.435 25.1423L133.155 31.1097H125.797L121.348 24.4538C119.768 22.0752 118.687 20.5798 118.105 19.9678C117.523 19.3418 116.907 18.9176 116.255 18.695C115.604 18.4586 114.572 18.3403 113.158 18.3403H111.911V31.1097H105.758ZM111.911 13.4579H116.463C119.415 13.4579 121.258 13.3327 121.993 13.0823C122.727 12.832 123.302 12.4008 123.718 11.7887C124.134 11.1767 124.342 10.4116 124.342 9.49357C124.342 8.46423 124.064 7.63659 123.51 7.01064C122.97 6.37078 122.201 5.96739 121.203 5.80047C120.704 5.73092 119.207 5.69614 116.713 5.69614H111.911V13.4579Z" fill="#1C1C1D"/>
                <path d="M166.112 31.1097H159.418L156.757 24.1617H144.576L142.061 31.1097H135.534L147.403 0.521625H153.91L166.112 31.1097ZM154.783 19.008L150.584 7.65745L146.468 19.008H154.783Z" fill="#1C1C1D"/>
                <path d="M175.225 31.1097V5.69614H166.182V0.521625H190.4V5.69614H181.378V31.1097H175.225Z" fill="#1C1C1D"/>
                <rect x="208.605" y="13.2979" width="6.2085" height="17.7608" fill="#1C1C1D"/>
                <rect x="221.023" y="3.5293" width="6.2085" height="27.5293" fill="#1C1C1D"/>
                <rect x="245.855" y="13.2979" width="6.2085" height="17.7608" fill="#1C1C1D"/>
                <rect x="233.441" y="19.5142" width="6.20848" height="11.5445" fill="#1C1C1D"/>
            </svg>
        </div>
        <div class="flex flex-col w-full">

            <form class="flex-row w-full m-0">
                <div class="mb-10 w-full">
                    <h3 class="fs-28 fw-700 mb-1 welcome-text">Hello there!</h3>
                    <p class="subtitle fw-300 fs-20">Let’s get you signed up for Instrat360</p>
                </div>

                <div class="flex gap-6">
                  <div class="form_group">
                    <input type="text" autocomplete="on" v-model="cred.first_name" placeholder="First name" >
                  </div>

                  <div class="form_group">
                    <input type="text" autocomplete="on" v-model="cred.last_name" placeholder="Last name" >
                  </div>
                </div>


                <div class="form_group">
                    <input type="email" autocomplete="on" v-model="cred.email" placeholder="Your email" >
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M2 6C2 4.89543 2.89543 4 4 4H20C21.1046 4 22 4.89543 22 6V18C22 19.1046 21.1046 20 20 20H4C2.89543 20 2 19.1046 2 18V6ZM5.51859 6L12 11.6712L18.4814 6H5.51859ZM20 7.32877L12.6585 13.7526C12.2815 14.0825 11.7185 14.0825 11.3415 13.7526L4 7.32877V18H20V7.32877Z" fill="#3D3D3D"/>
                    </svg>
                </div>

                <div class="form_group">
                    <input type="text" v-model="referralCode" placeholder="Referral Code" >
                </div>

<!--                <div class="form_group phone_number flex">-->
<!--                  <div class="countryContainer flex gap-3 relative">-->
<!--                    <svg width="28" height="20" viewBox="0 0 28 20" fill="none" xmlns="http://www.w3.org/2000/svg">-->
<!--                      <rect x="0.25" y="0.25" width="27.5" height="19.5" rx="1.75" fill="white" stroke="#F5F5F5" stroke-width="0.5"/>-->
<!--                      <mask id="mask0_15529_44652" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="28" height="20">-->
<!--                        <rect x="0.25" y="0.25" width="27.5" height="19.5" rx="1.75" fill="white" stroke="white" stroke-width="0.5"/>-->
<!--                      </mask>-->
<!--                      <g mask="url(#mask0_15529_44652)">-->
<!--                        <path fill-rule="evenodd" clip-rule="evenodd" d="M2 0C0.895431 0 0 0.895431 0 2V8H8V0H2ZM12 0V8H28V2C28 0.895431 27.1046 0 26 0H12ZM28 12H12V20H26C27.1046 20 28 19.1046 28 18V12ZM8 20V12H0V18C0 19.1046 0.895431 20 2 20H8Z" fill="#EF264D"/>-->
<!--                      </g>-->
<!--                    </svg>-->
<!--                    <svg width="12" height="7" viewBox="0 0 12 7" fill="none" xmlns="http://www.w3.org/2000/svg">-->
<!--                      <path d="M11 1L6 6L1 1" stroke="#8D8D8D" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>-->
<!--                    </svg>-->
<!--                  </div>-->
<!--                  <span class="text-color-616161 fs-14 fw-400 ml-4 mr-2">+45</span>-->
<!--                  <input type="text" v-model="cred.phone" placeholder="Your phone number" >-->
<!--                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">-->
<!--                    <path d="M18 20.25V3.75C18 2.92157 17.3284 2.25 16.5 2.25L7.5 2.25C6.67157 2.25 6 2.92157 6 3.75L6 20.25C6 21.0784 6.67157 21.75 7.5 21.75H16.5C17.3284 21.75 18 21.0784 18 20.25Z" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>-->
<!--                    <path d="M12 6.75C12.6213 6.75 13.125 6.24632 13.125 5.625C13.125 5.00368 12.6213 4.5 12 4.5C11.3787 4.5 10.875 5.00368 10.875 5.625C10.875 6.24632 11.3787 6.75 12 6.75Z" fill="black"/>-->
<!--                  </svg>-->
<!--                </div>-->

                <div class="form_group">
                    <input type="password" v-model="cred.password" placeholder="Type your password" >
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M12 4C13.6477 4 15 5.35228 15 7V10H9V7C9 5.35228 10.3523 4 12 4ZM17 10V7C17 4.24772 14.7523 2 12 2C9.24771 2 7 4.24772 7 7V10H6C4.89543 10 4 10.8954 4 12V20C4 21.1046 4.89543 22 6 22H18C19.1046 22 20 21.1046 20 20V12C20 10.8954 19.1046 10 18 10H17ZM6 12H18V20H6V12Z" fill="#3D3D3D"/>
                    </svg>
                </div>

                <div class="form_group">
                    <input type="password" v-model="cred.check_password" placeholder="Retype your password" >
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M12 4C13.6477 4 15 5.35228 15 7V10H9V7C9 5.35228 10.3523 4 12 4ZM17 10V7C17 4.24772 14.7523 2 12 2C9.24771 2 7 4.24772 7 7V10H6C4.89543 10 4 10.8954 4 12V20C4 21.1046 4.89543 22 6 22H18C19.1046 22 20 21.1046 20 20V12C20 10.8954 19.1046 10 18 10H17ZM6 12H18V20H6V12Z" fill="#3D3D3D"/>
                    </svg>
                </div>

                <div class="flex items-center justify-between -mt-3">
                    <div class="flex items-center relative">
                      <label class="main">
                        <span class="i-ml-25 cursor-pointer fs-14 fw-400 text-secondary-one">Stay signed in</span>
                        <input type="checkbox">
                        <span class="geekmark i-border-1 border-solid border-secondary-one"></span>
                      </label>
                    </div>
                </div>
                <div class="flex-row mt-9">
                    <div class="max-w-full">
                        <button class="w-full block uppercase rounded text-center fw-500 focus:outline-none fs-18 bg-primary-one text-primary-two pt-3 pb-3" @click="registerSubmit($event)">Sign up</button>
                    </div>
                    <div class="mx-auto mt-8">
                        <p class="text-center fs-14 fw-400 text-secondary-one">
                          Already have an account?
                          <router-link :to="{ name:'login' }" class="text-primary-four">Sign in</router-link>
                        </p>
                    </div>
                </div>

            </form>
        </div>
      </div>
   </div>
</template>

<script setup>
  import CountrySelect from '@/elements/CountrySelect';
</script>

<script>
    export default {
        created(){
            document.title = 'Register'
        },
        data() {
            return {
              cred: {},
              referralCode: '',
              backgroundImage: `url(${require('@/assets/images/loginPage.webp')})`
            }
        },
        methods: {
          registerSubmit(e) {
            if(this.referralCode !== 'PRERELEASE'){
              alert('Referral code dose not match!')
              return;
            }
            e.preventDefault();
            if(this.cred.email && this.cred.password && this.cred.check_password) {
              if (this.cred.password !== this.cred.check_password) {
                alert('Password not matched!');
                return false
              }
              this.cred.phone = null;
              this.$Progress.start();
              this.$store.dispatch("ACT_REGISTRATION", this.cred).then(response => {
                this.$Progress.finish();
                if(response.data.success) {
                  this.$router.push({ name: 'verify_notice' });
                }
              })
              // if(this.cred.reference_code === 'PRE_RELEASE') {
              //   //
              // } else {
              //   alert('Reference not matched');
              // }
            } else {
              alert('Please fill all the field');
            }
          }
        }
    }
</script>

<style scoped>
  a[href="/"]{
    user-select: all;
    pointer-events: auto;
    opacity: 1;
    cursor: pointer;
  }
  .auth_wrapper{
    height: 1080px;
    width: 100%;
    background-color: var(--color-primary-three);
  }
  .left-info-box{
    margin-top: 330px;
  }
  .auth_wrapper .left{
    width: 55%;
    background-image: linear-gradient(rgb(65 161 212 / 95%), rgb(43 128 173 / 89%)), var(--bg);
    background-repeat: no-repeat;
    background-position: center;
    padding-left: 5%;
    padding-right: 5%;
  }
  .phone_number{
    display: flex;
  }
  .auth_wrapper .right{
    width: 45%;
    padding-left: 11%;
    padding-right: 11%;
    padding-top: 220px
  }

  .form_group{
    position: relative;
    margin-bottom: 30px;
    border: 1px solid #C9C9C9;
    line-height: 46px;
    border-radius: 4px;
    width: 100%;
  }
  .form_group input{
    width: 100%;
    height: 46px;
    background: transparent;
    padding: 12px 45px 12px 16px;
  }

  .form_group>svg{
    position: absolute;
    right: 15px;
    top: 50%;
    transform: translateY(-50%)
  }
  .phone_number>.countryContainer{
    width: 80px;
    align-items: center;
    justify-content: center;
    padding: 0 12px;
    border-right: 1px solid #C9C9C9;
  }

  @media all and (max-width: 1440px){
    .auth_wrapper .left{
      width: 50%;
    }
    .auth_wrapper .left .title{
      font-size: 42px;
      line-height: 64px;
    }
    .auth_wrapper .description{
      font-size: 28px;
      line-height: 47px;
    }
    .auth_wrapper .right{
      width: 50%;
      padding-left: 8%;
      padding-right: 8%;
      padding-top: 220px;
    }
  }

  @media all and (max-width: 1100px){
  .auth_wrapper .left .title{
      font-size: 30px;
      line-height: 64px;
    }
    .auth_wrapper .description{
      font-size: 20px;
      line-height: 35px;
    }
  }

  @media all and (max-width: 1000px){
      .auth_wrapper .right{
          max-width: 600px;
          width: 100%;
          padding: 0 10%;
          margin: auto;
          padding-bottom: 50px;
      }
      .auth_wrapper{
          height: 100vh;
      }
      .auth_wrapper .left{
          display: none;
      }
      .formTopText{
          text-align: center;
      }
      .logo{
          width: 190px;
          position: static;
          margin: auto;
          margin-top: 110px;
          margin-bottom: 32px;
      }
      .logo>svg{
          width: 100%;
      }

      .welcome-text{
          font-size: 20px;
          text-align: center;
      }
      .subtitle{
          font-size: 14px;
          text-align: center;
      }
  }
</style>
